<template>
  <v-tooltip
    :color="currentState.color"
    right
  >
    <template v-slot:activator="{ on, attrs }">
      <div
        class="text-center d-flex align-center justify-space-around"
        v-bind="attrs"
        v-on="on"
      >
        <v-btn
          icon
          x-small
          class="pa-0"
          @click="handleClick"
        >
          <v-icon
            v-show="show"
            small
          >
            {{ icons.mdiContentCopy }}
          </v-icon>
        </v-btn>
      </div>
    </template>
    <span>{{ currentState.text }}</span>
  </v-tooltip>
</template>
<script>
import { mdiContentCopy } from '@mdi/js'
import { defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  props: {
    value: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const state = {
      default: { text: 'Cliquer pour copier' },
      copied: { text: 'Copié!', color: 'primary' },
    }
    const currentState = ref(state.default)

    const handleClick = () => {
      navigator.clipboard.writeText(props.value)
      currentState.value = state.copied
      setTimeout(() => {
        currentState.value = state.default
      }, 2000)
    }

    return {
      handleClick,
      currentState,
      icons: { mdiContentCopy },
    }
  },
})
</script>
<style lang="scss" scoped>
.v-icon {
  color: rgb(155, 155, 155) !important;
}
</style>
