<template>
  <div>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      max-width="300"
      offset-y
    >
      <template #activator="{ on, attrs }">
        <v-btn
          class="add-field-btn"
          :elevation="0"
          dense
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>{{ icons.mdiPlus }}</v-icon>
          Ajouter une caractéristique
        </v-btn>
      </template>

      <v-card>
        <v-card-text class="pa-0 mt-0">
          <v-list>
            <v-list-item-group
              active-class="active-class-color"
            >
              <v-list-item
                v-for="(item, i) in items"
                :key="i"
                style="height: 0px"
                @click="handleFieldTypeClick(item)"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content class="pl-2">
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { mdiPlus } from '@mdi/js'
import {
  computed, onMounted, onUpdated, ref,
} from '@vue/composition-api'
import fieldTypes from '@/fieldTypes'
import store from '@/store'

export default {
  setup(props, context) {
    const fav = ref(true)
    const menu = ref(false)
    const message = ref(false)
    const hints = ref(true)
    const items = ref(fieldTypes)
    const newFieldName = ref()
    const selectedModel = ref(store.getters['models/getSelectedModel'])
    const numberOfFields = computed(() => selectedModel.value.length)

    onMounted(() => {
      newFieldName.value = null
    })
    onUpdated(() => {
      if (!newFieldName.value) {
        newFieldName.value = `Ma propriété ${numberOfFields.value + 1}`
      }
    })

    const handleFieldTypeClick = field => {
      menu.value = false
      context.emit('clickOnFieldType', field)
    }

    return {
      newFieldName,
      selectedModel,
      fav,
      menu,
      message,
      hints,
      items,
      handleFieldTypeClick,
      icons: { mdiPlus },
    }
  },
}
</script>
<style>
.add-field-btn {
  text-transform: none;
}
</style>
