<template>
  <v-dialog
    :close-on-click="false"
    width="400"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        color="error"
        outlined
        small
        :elevation="0"
        v-bind="attrs"
        v-on="on"
      >
        Supprimer le champ
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="ma-2">
        Êtes vous sûr de vouloir supprimer ce champ ?
      </v-card-title>
      <v-card-text>
        Cette action est irréversible, et sera appliquée a tous les contacts du carnet.
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          small
          class="mt-8 ma-1"
          color="error"
          @click="onClickDelete($event)"
        >
          Confirmer la suppression
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  setup(props, context) {
    const onClickDelete = event => {
      context.emit('deleteField', event)
    }

    return {
      onClickDelete,
    }
  },
})
</script>

<style scoped>
.v-btn--outlined {
  border-width: 0;
}
</style>
