<template>
  <div>
    <v-menu
      v-if="activeSort"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          text
          class="pa-0"
          style="height: auto !important; width: auto !important"
          v-bind="attrs"
          v-on="on"
        >
          <span><b>Trié par: </b>{{ activeSort.title }}</span>
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="pa-0">
        </v-card-title>
        <v-card-text class="pa-0">
          <div
            v-for="(item, index) in items"
            :key="index"
            cols="12"
          >
            <v-btn
              x-small
              text
              class="mb-1"
              :class="{'font-weight-bold': item.active}"
              style="width: 100%"
              @click="setSort(item)"
            >
              <v-list-item-title>
                <span>{{ item.title }}</span>
              </v-list-item-title>
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>
<script>
import { computed, defineComponent } from '@vue/composition-api'
import store from '@/store'

export default defineComponent({
  setup() {
    const selectedModel = computed(() => store.getters['models/getSelectedModel'])
    const sorts = computed(() => (selectedModel.value ? store.getters['sorts/getSortsByModelId'](selectedModel.value.id) : []))
    const activeSort = computed(() => sorts.value.find(sort => sort.active))
    const setSort = item => {
      store.dispatch('sorts/DELETE_SORT', { modelId: selectedModel.value.id, id: activeSort.value.id })
      store.dispatch('sorts/ADD_SORT', { modelId: selectedModel.value.id, type: item.type })
    }

    return {
      setSort,
      items: sorts,
      activeSort,
    }
  },
})
</script>
<style scoped>
.v-btn {
  text-transform: none !important;
}
</style>
