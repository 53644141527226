<template>
  <v-dialog
    :close-on-click="false"
    width="400"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        style="text-transform: none"
        small
        class="mt-8 ma-1"
        color="gray"
        v-bind="attrs"
        text
        v-on="on"
      >
        <v-icon>{{ icons.mdiDelete }}</v-icon>
        Supprimer
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="ma-2">
        Êtes vous sûr de vouloir supprimer ce contact ?
      </v-card-title>
      <v-card-text>
        Cette action est irréversible.
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          small
          class="mt-8 ma-1"
          color="error"
          @click="deleteContact"
        >
          Confirmer la suppression
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiDelete } from '@mdi/js'
import store from '@/store'

export default {
  props: {
    contactId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    // Delete current contact from store
    const deleteContact = () => {
      store.dispatch('contacts/delete', props.contactId)
      store.dispatch('contacts/UNSELECT_CONTACT')
    }

    return {
      deleteContact,
      icons: { mdiDelete },
    }
  },
}
</script>
