<template>
  <v-card class="card-border">
    <v-card-text class="pa-0">
      <v-autocomplete
        :items="fieldValues"
        filled
        chips
        multiple
        hide-details
        :value="selectedFilteredValues"
        item-text="name"
        item-value="name"
        return-object
        @input="onChange"
        @focus="isFocused = true"
        @blur="isFocused = false"
      >
        <template v-slot:label>
          <span><v-icon>{{ icons.mdiFilter }}</v-icon>{{ fieldName }}</span>
        </template>
        <template v-slot:selection="{ item, index }">
          <div class="pt-1">
            <v-chip
              v-if="index == 3 && !isFocused"
              label
            >
              ...
            </v-chip>
            <div
              v-else-if="index > 3 && !isFocused"
            ></div>
            <v-chip
              v-else
              :style="`max-width: ${maxWidthFilterChip};`"
              label
              close
              @click:close="removeFilterByValue(item)"
            >
              <v-avatar
                v-if="fieldType === 'company'"
                color="accent"
                left
                size="24"
              >
                <span v-if="item.logo"><v-img
                  width="24"
                  style="margin-top: 3px"
                  :src="item.logo"
                /></span><span
                  v-else
                  class="white--text"
                >{{ item.name[0] }}</span>
              </v-avatar>
              <span class="pr-2 text-truncate">
                {{ item.name ? item.name : item }}
              </span>
            </v-chip>
          </div>
        </template>
        <template v-slot:item="{ item, on, attrs }">
          <!-- Filters on string -->
          <v-list-item
            v-if="typeof item === 'string'"
            #default="{ active }"
            class=""
            :disabled="loading"
            v-bind="attrs"
            v-on="on"
          >
            <v-list-item-action>
              <v-checkbox
                :disabled="loading"
                :input-value="active"
              ></v-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <span>{{ item }}</span>
            </v-list-item-content>
          </v-list-item>
          <!-- Filters on Company objects -->
          <v-list-item
            v-else
            :disabled="loading"
            v-bind="attrs"
            v-on="on"
          >
            <v-list-item-avatar>
              <v-avatar
                color="accent"
                left
                size="32"
              >
                <span v-if="item.logo"><v-img
                  width="32"
                  style="margin-top: 3px"
                  :src="item.logo"
                /></span><span
                  v-else
                  class="white--text"
                >{{ item.name[0] }}</span>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
              <v-list-item-subtitle>{{ item.domain }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-autocomplete>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiFilter } from '@mdi/js'
import { computed, defineComponent, ref } from '@vue/composition-api'
import _ from 'lodash'
import store from '@/store'

export default defineComponent({
  props: {
    filters: {
      type: Array,
      required: true,
    },
    fieldId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const selectedModel = computed(() => store.getters['models/getSelectedModel'])
    const selectedFilteredValues = computed(() => _.map(props.filters, 'fieldValue'))
    const fieldValues = computed(() => store.getters['contacts/getCurrentFieldValuesById'](props.fieldId).concat(selectedFilteredValues.value))
    const fieldType = ref(store.getters['models/getFieldTypeById'](props.fieldId))
    const fieldName = computed(() => (props.filters ? props.filters[0].fieldName : null))
    const loading = ref(false)
    const isFocused = ref(false)
    const addFilter = fieldValue => store.dispatch('filters/CREATE_FILTER', {
      fieldId: props.fieldId,
      fieldName: props.filters ? props.filters[0].fieldName : null,
      fieldValue,
      modelId: selectedModel.value.id,
    })

    const removeFilter = id => store.dispatch('filters/delete', id)
    const removeFilterByValue = value => {
      const fieldValue = JSON.parse(JSON.stringify(value))
      const filterFound = props.filters.find(filter => _.isEqual(JSON.parse(JSON.stringify(filter.fieldValue)), fieldValue))
      removeFilter(filterFound.id)
    }

    const onChange = value => {
      loading.value = true
      const updatedFiltersValue = JSON.parse(JSON.stringify(value))
      const existingFiltersValue = _.map(props.filters, 'fieldValue')
      const deletedFilterValue = _.differenceWith(existingFiltersValue, updatedFiltersValue, _.isEqual)[0]
      const addedFilterValue = _.differenceWith(updatedFiltersValue, existingFiltersValue, _.isEqual)[0]
      const deletedFilter = props.filters.find(filter => filter.fieldValue === deletedFilterValue)
      if (addedFilterValue) {
        addFilter(addedFilterValue).then(() => {
          loading.value = false
        })
      }
      if (deletedFilter) {
        removeFilter(deletedFilter.id).then(() => {
          loading.value = false
        })
      }
    }

    const maxWidthFilterChip = computed(() => {
      const filtersCount = selectedFilteredValues.value.length
      if (isFocused.value) {
        return '100%'
      }
      if (filtersCount > 3) {
        return fieldType.value === 'company' ? '2rem' : '4rem'
      }

      return `${Math.round(10 / filtersCount)}rem`
    })

    return {
      maxWidthFilterChip,
      selectedFilteredValues,
      fieldValues,
      loading,
      fieldType,
      fieldName,
      isFocused,
      icons: {
        mdiFilter,
      },
      removeFilterByValue,
      onChange,
    }
  },
})
</script>
<style>
::v-deep .v-chip__content {
  display: inline-block !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

::v-deep .v-chip__close {
  position: absolute;
  top: 5px;
  right: 0;
  width: 24px;
}
</style>
