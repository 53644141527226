<template>
  <div
    class="d-flex"
    style="width: 100%;"
  >
    <div
      v-for="(filters, fieldId) in groupedFilters"
      :key="fieldId"
      class="mr-2"
      style="max-width: 33%"
    >
      <FilterGroup
        :filters="filters"
        :field-id="fieldId"
      />
    </div>
    <div
      v-for="field in suggestionFieldsForFiltering"
      :key="field.id"
      class="align-self-end mr-2"
      style="max-width: 33%"
    >
      <AddFilter
        v-if="hasFieldValues(field.id) && field.name"
        :label="`Filtrer par ${field.name}`"
        :field-id="field.id"
        :show-icon="false"
      />
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from '@vue/composition-api'
import AddFilter from '@/components/AddFilter.vue'
import FilterGroup from '@/components/FilterGroup.vue'
import store from '@/store'

export default defineComponent({
  components: {
    FilterGroup,
    AddFilter,
  },
  setup() {
    const selectedModel = computed(() => store.getters['models/getSelectedModel'])
    const groupedFilters = computed(() => store.getters['filters/getGroupedFiltersByModelId'](selectedModel.value ? selectedModel.value.id : null))
    const suggestionFieldsForFiltering = computed(() => store.getters['models/getFieldsSuggestionsForFiltering'].filter(
      field => Object.keys(groupedFilters.value).indexOf(field.id) === -1,
    ))
    const hasFieldValues = computed(
      () => fieldId => store.getters['contacts/getCurrentFieldValuesById'](fieldId).length > 0,
    )

    return {
      groupedFilters,
      selectedModel,
      suggestionFieldsForFiltering,
      hasFieldValues,
    }
  },
})
</script>
