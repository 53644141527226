<template>
  <div class="elevation-0">
    <v-card
      v-if="contact"
      :elevation="10"
    >
      <v-card-title>
        <v-row v-show="lastUpdatedAt">
          <v-col
            align="right"
            class="pa-0"
          >
            <v-card-subtitle class="ma-0 pa-0 text-caption">
              Dernière mise à jour: {{ lastUpdatedAt }}
            </v-card-subtitle>
          </v-col>
        </v-row>
        <v-row class="pt-3">
          <v-col
            cols="auto"
            align-self="center"
            class="d-flex "
          >
            <a
              class="justify-end"
              small
              @click="unselectContact"
            >
              <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
            </a>
          </v-col>
          <v-col cols="auto">
            <v-badge
              color="none"
              rounded
              avatar
              offset-x="25"
              offset-y="20"
            >
              <template
                v-if="companyDomain"
                v-slot:badge
              >
                <v-avatar
                  :class="'v-avatar-light-bg primary--text company_badge'"
                  size="20"
                >
                  <span><v-img
                    width="20"
                    style="margin-top: 3px"
                    :src="`https://logo.clearbit.com/${companyDomain}`"
                  /></span>
                </v-avatar>
              </template>

              <v-avatar
                :color="'primary'"
                :class="'v-avatar-light-bg primary--text'"
                size="120"
                rounded
              >
                <span class="font-weight-semibold text-5xl">{{ avatarText }}</span>
              </v-avatar>
            </v-badge>
          </v-col>
          <!-- First / Last name -->
          <v-col cols="8">
            <div
              v-for="field in namesContactFields"
              :key="field.id"
            >
              <ContactField
                v-model="field.value"
                :field-id="field.id"
                :field-name="field.name"
                :field-type="field.type"
                :personal-info="field.personalInfo"
                :required="field.required"
                title
                @readyToCommit="saveField(field)"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text
        class="tab-content"
      >
        <h2 class="pl-5 pr-5">
          Détails
        </h2>
        <v-row v-if="selectedModel.fields">
          <v-col cols="12">
            <v-data-table
              id="fields"
              class="pt-2"
              hide-default-header
              hide-default-footer
              :headers="headers"
              :items="otherContactFields"
              :items-per-page="-1"
            >
              <template v-slot:body="{ items }">
                <draggable
                  class="draggable-field"
                  v-bind="dragOptions"
                  :list="items"
                  tag="tbody"
                  handle=".handle"
                  @end="changeOrder(items)"
                >
                  <tr
                    v-for="item in items"
                    :key="item.id"
                    style="font-size: 100 !important"
                  >
                    <td class="field-row labelColumn">
                      <ContactField
                        v-model="item.name"
                        name
                        :autofocus="item.id == autofocusFieldId"
                        field-type="text"
                        :field-id="item.id"
                        :icon="getIconByType(item.type)"
                        @readyToCommit="saveModelFieldName(item)"
                      />
                    </td>
                    <td class="field-row valueColumn">
                      <ContactField
                        v-model="item.value"
                        :field-id="item.id"
                        :field-name="item.name"
                        :field-type="item.type"
                        :personal-info="item.personalInfo"
                        :required="item.required"
                        @remove="removeField(item.id)"
                        @readyToCommit="saveField(item)"
                      />
                    </td>
                    <td class="handle field-row actionsColumn">
                      <FieldKebabMenu
                        :field="item"
                        @deleteField="removeField(item.id)"
                        @filterField="filterField(item)"
                      />
                    </td>
                  </tr>
                </draggable>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <AddFieldMenu @clickOnFieldType="addField($event)" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <h2 class="mb-5 pl-5 pr-5">
              Notes
            </h2>
            <ContactField
              v-model="contact.notes"
              :field-id="'notes'"
              :field-name="'Notes'"
              :field-type="'textarea'"
              :personal-info="true"
              :required="false"
              @readyToCommit="saveNotes(contact.notes)"
            />
          </v-col>
          <v-col cols="6">
            <TaskList
              :contact-id="contact.id"
              :default-interval="'all'"
            />
          </v-col>
        </v-row>
        <v-row class="d-flex justify-end">
          <v-col class="d-flex justify-end">
            <DeleteContact
              :contact-id="contact.id"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {
  mdiArrowLeft, mdiCheck, mdiCloseCircleOutline, mdiDelete, mdiDotsVertical,
} from '@mdi/js'
import { computed, defineComponent, ref } from '@vue/composition-api'
import moment from 'moment'
import draggable from 'vuedraggable'
import store from '@/store'
import { getIconByType } from '@/fieldTypes'
import ContactField from '@/components/ContactField.vue'
import AddFieldMenu from './AddFieldMenu.vue'
import DeleteContact from './DeleteContact.vue'
import FieldKebabMenu from './FieldKebabMenu.vue'
import TaskList from './TaskList.vue'

export default defineComponent({
  components: {
    AddFieldMenu,
    ContactField,
    DeleteContact,
    FieldKebabMenu,
    draggable,
    TaskList,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const contact = computed(() => store.getters['contacts/getContactById'](props.id))
    const changed = ref(false)
    const lastUpdatedAt = computed(() => {
      if (contact.value.updated_at) return moment.unix(contact.value.updated_at.seconds).fromNow()

      return null
    })
    const autofocusFieldId = ref(null)
    const headers = ref([
      {
        text: 'actions',
        value: 'actions',
        cellClass: 'actionsColumn',
      },
      {
        text: 'name',
        value: 'name',
        cellClass: 'labelColumn',
      },
      {
        text: 'value',
        value: 'value',
      },
    ])
    const selectedModel = ref(store.getters['models/getSelectedModel'])
    const nextOrderKeyField = computed(() => store.getters['models/nextOrderKeyField'])

    const namesContactFields = computed(() => store.getters['contacts/getNamesFields'](contact.value))
    const otherContactFields = computed(() => store.getters['contacts/getOtherFields'](contact.value))

    const avatarText = computed(() => {
      const { fields } = contact.value
      let first = ''
      let second = ''
      if (fields) {
        first = fields.firstName ? fields.firstName[0] : ''
        second = fields.lastName ? fields.lastName[0] : ''
      }

      return `${first}${second}`.toLocaleUpperCase()
    })

    // Add field to current contact
    const addField = ({ text, fieldType, personalInfo }) => {
      const modelId = selectedModel.value.id
      const id = `field_${Math.floor(Math.random() * 100000)}`

      const field = {
        id,
        name: text,
        type: fieldType,
        orderKey: nextOrderKeyField.value,
        deletable: true,
        personalInfo,
      }
      store.dispatch('models/set', { id: modelId, fields: { [field.id]: field } })
      autofocusFieldId.value = field.id
    }

    const saveModelFieldName = field => {
      store.dispatch('models/patch', {
        id: selectedModel.value.id,
        fields: {
          [field.id]: { name: field.name },
        },
      })
    }

    const updateFieldOrder = (field, orderKey) => {
      store.dispatch('models/patch', {
        id: selectedModel.value.id,
        fields: {
          [field.id]: { orderKey },
        },
      })
    }

    const changeOrder = items => {
      Object.values(items).forEach((item, index) => {
        if (item.orderKey - 3 !== index) {
          updateFieldOrder(item, index + 3)
        }
      })
    }

    const saveField = field => {
      store.dispatch('contacts/patch', {
        id: contact.value.id,
        fields: { [field.id]: field.value || null },
      })
    }

    const saveNotes = notes => {
      store.dispatch('contacts/patch', {
        id: contact.value.id,
        notes: notes || '',
      })
    }

    // Unselect current contact
    const unselectContact = () => {
      store.dispatch('contacts/UNSELECT_CONTACT')
    }

    // Remove field from current contact
    const removeField = id => {
      const modelId = selectedModel.value.id
      store.dispatch('models/delete', `${modelId}.fields.${id}`)
    }

    const companyDomain = computed(() => store.getters['contacts/getCompanyDomain'](contact.value))

    // Create a filter based on the field
    const filterField = field => {
      const fieldId = field.id
      const fieldName = field.name
      const fieldValue = field.value
      const modelId = selectedModel.value.id
      if (fieldId && fieldName && fieldValue) {
        store.dispatch('filters/CREATE_FILTER', {
          fieldId,
          fieldName,
          fieldValue,
          modelId,
        })
      }
    }

    const dragOptions = computed(() => ({
      group: 'fields',
      animation: 150,
    }))

    return {
      changed,
      lastUpdatedAt,
      namesContactFields,
      otherContactFields,
      selectedModel,
      avatarText,
      companyDomain,
      autofocusFieldId,
      saveModelFieldName,
      unselectContact,
      removeField,
      saveField,
      saveNotes,
      addField,
      filterField,
      changeOrder,
      icons: {
        mdiCheck,
        mdiArrowLeft,
        mdiCloseCircleOutline,
        mdiDotsVertical,
        mdiDelete,
      },
      contact,
      moment,
      headers,
      getIconByType,
      dragOptions,
    }
  },
})
</script>
<style lang="scss" scoped>
.company_badge {
  scale: 3 !important;
}
.draggable-filed:hover {
  background: transparent !important;
}

.labelColumn {
  min-width: 7rem !important;
  max-width: 7rem !important;
  overflow: hidden;
  text-overflow: ellipsis;
}
.valueColumn {
  width: auto;
  padding: 0 !important;
}
.actionsColumn {
  min-width: 1rem !important;
  max-width: 1rem !important;
  padding: 0 !important;
}
</style>
