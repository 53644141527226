<template>
  <v-menu
    top
    left
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        class="btn"
        icon
        tabindex="-1"
        v-bind="attrs"
        v-on="on"
      >
        {{ icons.mdiDragVertical }}
      </v-icon>
    </template>
    <v-list class="pa-0">
      <v-list-item class="pa-0">
        <AddFilterField
          @filterField="onClickFilter"
        />
      </v-list-item>
      <v-list-item
        v-if="field.deletable"
        class="pa-0"
      >
        <DeleteField
          @deleteField="onClickDelete"
        />
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mdiDragVertical } from '@mdi/js'
import { defineComponent } from '@vue/composition-api'
import AddFilterField from './AddFilterField.vue'
import DeleteField from './DeleteField.vue'

export default defineComponent({
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
  components: { DeleteField, AddFilterField },
  setup(props, context) {
    const onClickDelete = event => {
      context.emit('deleteField', event)
    }

    const onClickFilter = event => {
      context.emit('filterField', event)
    }

    return {
      icons: {
        mdiDragVertical,
      },
      onClickDelete,
      onClickFilter,
    }
  },
})
</script>
<style>
.btn {
  cursor: grab !important;
}
</style>
