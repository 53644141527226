<template>
  <div>
    <div class="d-flex mb-2">
      <FilterList />
      <SortMenu
        class="mr-auto text-truncate"
        style="width: 350px"
      />
    </div>
    <v-row no-gutters>
      <v-col
        off-set
        :cols="selectedContact ? 4 : 12"
      >
        <ContactList
          :selected-contact="selectedContact"
        />
      </v-col>
      <v-col
        v-if="selectedContact"
        class="pl-5"
        :cols="8"
      >
        <Contact
          :id="selectedContact"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { computed, defineComponent } from '@vue/composition-api'
import Contact from '@/components/Contact.vue'
import ContactList from '@/components/ContactList.vue'
import FilterList from '@/components/FilterList.vue'
import SortMenu from '@/components/SortMenu.vue'
import TaskList from '@/components/TaskList.vue'
import store from '@/store'

export default defineComponent({
  components: {
    ContactList,
    Contact,
    FilterList,
    TaskList,
    SortMenu,
  },
  setup() {
    const selectedContact = computed(() => store.getters['contacts/getSelectedContact'])

    return {
      selectedContact,
    }
  },
})
</script>
