var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"max-width":"300","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"add-filter-btn",attrs:{"color":"secondary","dark":"","text":!_vm.fieldId,"outlined":"","small":""}},'v-btn',attrs,false),on),[(_vm.showIcon)?_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiFilter)+" ")]):_vm._e(),(_vm.label)?_c('span',[_vm._v(_vm._s(_vm.label))]):_vm._e()],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',[(!_vm.fieldId)?_c('v-card-title',{staticClass:"pb-0"},[_c('h4',[_vm._v("Ajouter un filtre")])]):_vm._e(),_c('v-card-actions',{staticClass:"py-0"},[(!_vm.fieldId)?_c('v-select',{attrs:{"placeholder":"Choisissez un champ","items":_vm.availableFields,"item-text":'name',"item-value":'id'},model:{value:(_vm.selectedField),callback:function ($$v) {_vm.selectedField=$$v},expression:"selectedField"}}):_vm._e()],1),_c('v-card-actions',[_c('v-autocomplete',{attrs:{"autofocus":true,"menu-props":{ auto: true },"placeholder":"Choisissez une valeur","items":_vm.selectedFieldValues,"item-text":"name","item-value":"name","return-object":"","auto-select-first":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [(_vm.selectedFieldType === 'company')?_c('v-list-item-avatar',[_c('v-avatar',{attrs:{"left":"","color":item.logo ? '' : 'accent',"size":"32"}},[(item.logo)?_c('span',[_c('v-img',{staticStyle:{"margin-top":"3px"},attrs:{"src":item.logo,"width":"32"}})],1):_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(item.name[0]))])])],1):_vm._e(),(_vm.selectedFieldType === 'company')?_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.domain))])],1):_c('span',[_vm._v(_vm._s(item))])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [(_vm.selectedFieldType === 'company')?_c('v-list-item-avatar',[_c('v-avatar',{attrs:{"color":item.logo ? '' : 'accent',"left":"","size":"32"}},[(item.logo)?_c('span',[_c('v-img',{staticStyle:{"margin-top":"3px"},attrs:{"width":"32","src":item.logo}})],1):_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(item.name[0]))])])],1):_vm._e(),(_vm.selectedFieldType === 'company')?_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.domain))])],1):_c('span',[_vm._v(_vm._s(item))])]}}]),model:{value:(_vm.selectedFilterValue),callback:function ($$v) {_vm.selectedFilterValue=$$v},expression:"selectedFilterValue"}})],1),_c('v-card-actions',[_c('v-btn',{staticClass:"add-filter-btn",attrs:{"width":"100%","color":"primary","outlined":"","small":"","disabled":!_vm.selectedFilterValue},on:{"click":_vm.addFilter}},[_vm._v(" Appliquer le filtre ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }