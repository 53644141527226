<template>
  <v-btn
    color="secondary"
    outlined
    small
    :elevation="0"
    @click="filterField"
  >
    Filtrer sur le champ
  </v-btn>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  setup(props, context) {
    const filterField = event => {
      context.emit('filterField', event)
    }

    return {
      filterField,
    }
  },
})
</script>

<style scoped>
.v-btn--outlined {
  border-width: 0;
}
</style>
