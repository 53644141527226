<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="200"
    max-width="300"
    offset-y
  >
    <template #activator="{ on, attrs }">
      <v-btn
        class="add-filter-btn"
        color="secondary"
        dark
        :text="!fieldId"
        v-bind="attrs"
        outlined
        small
        v-on="on"
      >
        <v-icon v-if="showIcon">
          {{ icons.mdiFilter }}
        </v-icon>
        <span v-if="label">{{ label }}</span>
      </v-btn>
    </template>

    <v-card>
      <v-card-title
        v-if="!fieldId"
        class="pb-0"
      >
        <h4>Ajouter un filtre</h4>
      </v-card-title>
      <v-card-actions class="py-0">
        <v-select
          v-if="!fieldId"
          v-model="selectedField"
          placeholder="Choisissez un champ"
          :items="availableFields"
          :item-text="'name'"
          :item-value="'id'"
        ></v-select>
      </v-card-actions>
      <v-card-actions>
        <v-autocomplete
          v-model="selectedFilterValue"
          :autofocus="true"
          :menu-props="{ auto: true }"
          placeholder="Choisissez une valeur"
          :items="selectedFieldValues"
          item-text="name"
          item-value="name"
          return-object
          auto-select-first
        >
          <template v-slot:item="{index, item}">
            <v-list-item-avatar v-if="selectedFieldType === 'company'">
              <v-avatar
                left
                :color="item.logo ? '' : 'accent'"
                size="32"
              >
                <span v-if="item.logo">

                  <v-img
                    :src="item.logo"
                    style="margin-top: 3px"
                    width="32"
                  />
                </span>
                <span
                  v-else
                  class="white--text"
                >{{ item.name[0] }}</span>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content v-if="selectedFieldType === 'company'">
              <v-list-item-title>{{ item.name }}</v-list-item-title>
              <v-list-item-subtitle>{{ item.domain }}</v-list-item-subtitle>
            </v-list-item-content>
            <span v-else>{{ item }}</span>
          </template>
          <template v-slot:selection="{ item }">
            <v-list-item-avatar v-if="selectedFieldType === 'company'">
              <v-avatar
                :color="item.logo ? '' : 'accent'"
                left
                size="32"
              >
                <span
                  v-if="item.logo"
                ><v-img
                  style="margin-top: 3px"
                  width="32"
                  :src="item.logo"
                /></span><span
                  v-else
                  class="white--text"
                >{{ item.name[0] }}</span>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content v-if="selectedFieldType === 'company'">
              <v-list-item-title>{{ item.name }}</v-list-item-title>
              <v-list-item-subtitle>{{ item.domain }}</v-list-item-subtitle>
            </v-list-item-content>
            <span v-else>{{ item }}</span>
          </template>
        </v-autocomplete>
      </v-card-actions>
      <v-card-actions>
        <v-btn
          width="100%"
          class="add-filter-btn"
          color="primary"
          outlined
          small
          :disabled="!selectedFilterValue"
          @click="addFilter"
        >
          Appliquer le filtre
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { mdiFilter } from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import _ from 'lodash'
import store from '@/store'

export default {
  props: {
    label: {
      type: String,
      default: 'Filtrer',
    },
    fieldId: {
      type: String,
      default: null,
      required: false,
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const fav = ref(true)
    const menu = ref(false)
    const message = ref(false)
    const hints = ref(true)
    const fields = computed(() => store.getters['models/getAllCurrentModelFields'])
    const selectedModel = computed(() => store.getters['models/getSelectedModel'])

    const availableFields = computed(() => {
      const existingGroupedFilters = store.getters['filters/getGroupedFiltersByModelId'](
        selectedModel.value ? selectedModel.value.id : null,
      )

      return _.filter(fields.value, field => !existingGroupedFilters[field.id])
    })

    const selectedField = ref(props.fieldId ? props.fieldId : null)
    const selectedFieldValues = computed(() => store.getters['contacts/getCurrentFieldValuesById'](selectedField.value))
    const selectedFieldType = computed(() => store.getters['models/getFieldTypeById'](selectedField.value))
    const selectedFilterValue = ref(null)

    const addFilter = () => {
      menu.value = false
      store.dispatch('filters/CREATE_FILTER', {
        fieldId: selectedField.value,
        fieldName: fields.value.find(field => field.id === selectedField.value).name,
        fieldValue: selectedFilterValue.value,
        modelId: selectedModel.value.id,
      })
      selectedFilterValue.value = null
      selectedField.value = null
    }

    return {
      fav,
      menu,
      message,
      hints,
      fields,
      availableFields,
      selectedField,
      selectedFieldValues,
      selectedFieldType,
      selectedFilterValue,
      addFilter,
      icons: {
        mdiFilter,
      },
    }
  },
}
</script>
<style scoped>
.add-filter-btn {
  text-transform: none;
}
</style>
