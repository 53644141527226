<template>
  <div>
    <v-card>
      <v-card-title class="py-0">
        <v-row class="d-flex">
          <v-col class="ml-auto auto align-self-center">
            <v-text-field
              v-model="searchInput"
              :prepend-icon="icons.mdiMagnify"
              placeholder="Rechercher un contact..."
            ></v-text-field>
          </v-col>
          <v-col
            class="align-self-center"
            cols="auto"
          >
            <AddFilter :label="selectedContact ? null : 'Plus de filtres'" />
          </v-col>
          <v-col
            class="align-self-center"
            cols="auto"
          >
            <v-btn
              color="primary"
              class="my-2 mx-2"
              :loading="addContactLoading"
              small
              @click="addContact"
            >
              <v-icon>{{ icons.mdiPlus }}</v-icon>
              <span v-if="!selectedContact">Ajouter un contact</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        class="contact-list"
        disable-filtering
        :headers="headers.filter(e => e.base || !selectedContact)"
        :items="filteredSearchContacts"
        :footer-props="{ itemsPerPageOptions: [10, 20, 50] }"
        :items-per-page="10"
        :custom-sort="applyAllSorts"
        @click:row="handleRowClick"
      >
        <!-- name -->
        <template #[`item.contact`]="{ item }">
          <ContactDisplayName :contact="item" />
        </template>
        <template
          v-for="header in headers.filter(e => !e.base)"
          #[`item.${header.value}`]="{ item }"
        >
          <div
            v-if="!selectedContact"
            :key="header.value"
          >
            <v-list-item
              v-if="item.fields[header.value]"
            >
              <v-list-item-avatar v-if="header.type === 'company'">
                <v-avatar
                  left
                  :color="'primary'"
                  :class="'v-avatar-light-bg primary--text'"
                  size="32"
                >
                  <span><v-img
                    v-if="item.fields[header.value].logo"
                    width="32"
                    style="margin-top: 3px"
                    :src="item.fields[header.value].logo"
                  /><span
                    v-else
                    class="white--text"
                  >{{ item.fields[header.value].name[0] }}</span></span>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content v-if="header.type === 'company'">
                <v-list-item-title>{{ item.fields[header.value].name }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.fields[header.value].domain }}</v-list-item-subtitle>
              </v-list-item-content>
              <div v-else>
                {{ item.fields[header.value] }}
              </div>
            </v-list-item>
            <div
              v-else
              class="font-weight-thin"
            >
              <span>{{ header.text }}</span>
            </div>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import {
  mdiDotsVertical, mdiMagnify, mdiPlus, mdiSquareEditOutline,
} from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import _ from 'lodash'
import AddFilter from '@/components/AddFilter.vue'
import ContactDisplayName from '@/components/ContactDisplayName.vue'
import store from '@/store'

const { v4 } = require('uuid')

export default {
  components: { AddFilter, ContactDisplayName },
  setup() {
    const selectedContact = computed(() => store.getters['contacts/getSelectedContact'])
    const addContactLoading = ref(false)
    const searchInput = ref('')
    const selectedModel = computed(() => store.getters['models/getSelectedModel'] || null)
    const filters = computed(() => store.getters['filters/getFiltersByModelId'](selectedModel.value ? selectedModel.value.id : null))
    const contacts = computed(() => store.getters['contacts/getContacts'])
    const filteredContacts = computed(() => store.getters['contacts/getFilteredContacts'](filters.value))
    const filteredSearchContacts = computed(() => {
      const search = searchInput.value.toLowerCase()

      return filteredContacts.value.filter(e => {
        let concatFieldsToString = ''
        Object.keys(e.fields).forEach(key => {
          if (e.fields[key]) {
            if (typeof e.fields[key] === 'string') concatFieldsToString += e.fields[key].toLowerCase()
            else if (typeof e.fields[key] === 'object') {
              Object.keys(e.fields[key]).forEach(k => {
                if (e.fields[key][k]) {
                  concatFieldsToString += e.fields[key][k].toLowerCase()
                }
              })
            }
          }
        })

        return concatFieldsToString.toLowerCase().includes(search)
      })
    })
    const models = computed(() => store.getters['models/getModels'])
    const headers = computed(() => {
      const fields = selectedModel.value ? _.orderBy(selectedModel.value.fields, 'orderKey').slice(2, 5) : []
      const baseHeaders = [
        {
          text: 'Contact',
          value: 'contact',
          type: 'text',
          base: true,
          sortable: false,
          sort: null,
        },
      ]
      const otherFirstFields = _.map(fields, field => ({
        text: field.name,
        value: field.id,
        type: field.type,
        base: false,
        sortable: false,
      }))

      return baseHeaders.concat(otherFirstFields)
    })
    const handleRowClick = contact => {
      store.dispatch('contacts/SELECT_CONTACT', contact.id)
    }

    const handleModelChange = model => {
      store.dispatch('models/SET_SELECTED_MODEL', model.id)
    }

    const addContact = () => {
      const id = v4()
      store.dispatch('contacts/CREATE_CONTACT', { id, modelId: selectedModel.value.id })
      store.dispatch('contacts/SELECT_CONTACT', id)
    }

    const importContacts = () => {
      // Use this method instead of addContact to import all contacts from a json file
      const { contactsToImport } = require('@/contacts')

      let i = 0
      contactsToImport.forEach(async f => {
        if (i === 499) {
          const timer = ms => new Promise(res => setTimeout(res, ms))
          await timer(5000)
          i = 0
        }
        const fields = structuredClone(f)

        // Replace field_96156 and field_27618 with the id of the field in your model
        // These fields are company fields
        // eslint-disable-next-line
        const { company, field_96156, field_27618 } = fields
        if (company) {
          fields.company = { name: company }
        }
        // eslint-disable-next-line
        if (field_96156) {
          fields.field_96156 = { name: field_96156 }
        }
        // eslint-disable-next-line
        if (field_27618) {
          fields.field_27618 = { name: field_27618 }
        }
        const { notes } = structuredClone(fields)
        delete fields.notes
        const id = v4()
        store.dispatch('contacts/CREATE_CONTACT', {
          id,
          fields,
          notes,
          modelId: selectedModel.value.id,
        })
        i += 1
      })
    }

    const applyAllSorts = items => {
      let sortedItems = structuredClone(items)
      if (!selectedModel.value) return items
      store.getters['sorts/getSortsByModelId'](selectedModel.value.id)
        .filter(sort => sort.active)
        .forEach(sort => {
          sortedItems = sortedItems.sort((a, b) => sort.sortFunction(a, b))
        })

      return sortedItems
    }

    return {
      applyAllSorts,
      headers,
      contacts,
      searchInput,
      filteredContacts,
      filteredSearchContacts,
      handleModelChange,
      models,
      selectedModel,
      addContactLoading,
      handleRowClick,
      addContact,
      selectedContact,

      // icons
      icons: {
        mdiPlus,
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiMagnify,
      },

      // Only useful for importing contacts from a json file
      importContacts,
    }
  },
}
</script>

<style>
tr:hover td {
  cursor: pointer;
}
.theme--light.v-data-table.contact-list
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background-color: white;
  border-radius: 15px;
  transition-duration: 100ms;
  transition-delay: 0s;
  box-shadow: rgba(128, 128, 128, 0.24) 0px 3px 8px;
  transform: translateY(-2px);
}
</style>
