var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"card-border"},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-autocomplete',{attrs:{"items":_vm.fieldValues,"filled":"","chips":"","multiple":"","hide-details":"","value":_vm.selectedFilteredValues,"item-text":"name","item-value":"name","return-object":""},on:{"input":_vm.onChange,"focus":function($event){_vm.isFocused = true},"blur":function($event){_vm.isFocused = false}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiFilter))]),_vm._v(_vm._s(_vm.fieldName))],1)]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{staticClass:"pt-1"},[(index == 3 && !_vm.isFocused)?_c('v-chip',{attrs:{"label":""}},[_vm._v(" ... ")]):(index > 3 && !_vm.isFocused)?_c('div'):_c('v-chip',{style:(("max-width: " + _vm.maxWidthFilterChip + ";")),attrs:{"label":"","close":""},on:{"click:close":function($event){return _vm.removeFilterByValue(item)}}},[(_vm.fieldType === 'company')?_c('v-avatar',{attrs:{"color":"accent","left":"","size":"24"}},[(item.logo)?_c('span',[_c('v-img',{staticStyle:{"margin-top":"3px"},attrs:{"width":"24","src":item.logo}})],1):_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(item.name[0]))])]):_vm._e(),_c('span',{staticClass:"pr-2 text-truncate"},[_vm._v(" "+_vm._s(item.name ? item.name : item)+" ")])],1)],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [(typeof item === 'string')?_c('v-list-item',_vm._g(_vm._b({attrs:{"disabled":_vm.loading},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"disabled":_vm.loading,"input-value":active}})],1),_c('v-list-item-content',[_c('span',[_vm._v(_vm._s(item))])])]}}],null,true)},'v-list-item',attrs,false),on)):_c('v-list-item',_vm._g(_vm._b({attrs:{"disabled":_vm.loading}},'v-list-item',attrs,false),on),[_c('v-list-item-avatar',[_c('v-avatar',{attrs:{"color":"accent","left":"","size":"32"}},[(item.logo)?_c('span',[_c('v-img',{staticStyle:{"margin-top":"3px"},attrs:{"width":"32","src":item.logo}})],1):_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(item.name[0]))])])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.domain))])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }