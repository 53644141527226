<template>
  <v-container fluid>
    <v-row>
      <v-col class="pa-0 ma-0">
        <!-- Checkbox -->
        <div v-if="fieldType == 'checkbox'">
          <v-checkbox
            v-model="fieldValue"
            @change="saveFieldValue"
          />
        </div>
        <!-- Company -->
        <div v-else-if="fieldType == 'company'">
          <!-- Selected company -->
          <div v-if="value">
            <v-hover #default="{ hover: isHovered }">
              <v-chip
                label
                close
                @click:close="removeCompany()"
              >
                <v-avatar
                  :color="value.logo ? '' : 'accent'"
                  left
                  size="24"
                >
                  <span
                    v-if="value.logo"
                  ><v-img
                    style="margin-top: 3px"
                    width="24"
                    :src="value.logo"
                  /></span><span
                    v-else
                    class="white--text"
                  >{{ value.name[0] }}</span>
                </v-avatar>
                <span
                  :class="{'link': value.domain }"
                  @click="openWebSite()"
                >
                  {{ value.name }}
                </span>
                <CopyButton
                  :value="value.name"
                  :show="isHovered"
                />
              </v-chip>
            </v-hover>
          </div>
          <v-combobox
            v-else
            v-model="fieldValue"
            :class="{ 'title-text-field': title}"
            filled
            autocomplete="do-not-autofill"
            hide-details
            append-icon=""
            :search-input.sync="companySearch"
            :autofocus="autofocus"
            :items="companySuggestions"
            :placeholder="fieldName"
            :maxlength="name ? 25 : 255"
            solo
            flat
            :item-text="getCompanyItemText"
            overflow-visible
            :prepend-icon="icon"
            @input="onCompanyInput($event)"
          >
            <!-- Selected -->
            <template v-slot:selection="{ item }">
              {{ item.name }}
            </template>
            <!-- Suggestions -->
            <template v-slot:item="{ index, item }">
              <v-list-item-avatar>
                <v-avatar

                  :color="item.logo ? '' : 'accent'"
                  left
                  size="32"
                >
                  <span
                    v-if="item.logo"
                  ><v-img
                    style="margin-top: 3px"
                    width="32"
                    :src="item.logo"
                  /></span><span
                    v-else
                    class="white--text"
                  >{{ item.name[0] }}</span>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.domain }}</v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-combobox>
        </div>
        <!-- Date picker -->
        <div v-else-if="fieldType === 'date'">
          <v-menu
            v-model="menuDatePicker"
            :close-on-content-click="false"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                label
                readonly
                hide-details
                solo
                flat
                overflow-visible
                v-bind="attrs"
                v-on="on"
              >
                <span>{{ dateFieldText }} <span v-if="dateFieldHint">({{ dateFieldHint }})</span></span>
              </v-chip>
            </template>
            <v-date-picker
              v-model="fieldValue"
              color="primary"
              no-title
              locale="fr-fr"
              @input="onDatePickerInput($event)"
            ></v-date-picker>
          </v-menu>
        </div>
        <!-- Phone Field -->
        <!-- <div v-else-if="fieldType === 'phone'">
          <VuePhoneNumberInput
          v-model="tmpValue.formattedNumber"
            :default-country-code="tmpValue.countryCode"
            :border-radius=0
            size="sm"
            @phone-number-blur="onPhoneInput($event)"
            @update="formatPhoneNumber($event)"
          />
        </div> -->
        <div v-else-if="fieldType === 'textarea'">
          <v-row class="d-flex align-center textarea-bar px-3">
            <v-col cols="12">
              <v-textarea
                v-model="fieldValue"
                name="Prise de note"
                auto-grow
                :class="{ 'title-text-field': title}"
                filled
                autocomplete="do-not-autofill"
                solo
                flat
                rows="2"
                no-resize
                placeholder="Ajouter des notes..."
                :maxlength="1024"
                counter="1024"
                :dense="!title"
                overflow-visible
                @change="saveFieldValue"
              />
            </v-col>
          </v-row>
        </div>
        <div v-else-if="!personalInfo && !name">
          <v-combobox
            v-model="fieldValue"
            :class="{ 'title-text-field': title}"
            filled
            autocomplete="do-not-autofill"
            hide-details
            :autofocus="autofocus"
            append-icon=""
            :items="existingFieldValues"
            :placeholder="fieldName ? fieldName : 'Sans nom'"
            :maxlength="name ? 25 : 255"
            :dense="!title"
            solo
            flat
            overflow-visible
            :prepend-icon="icon"
            @focus="autoSelect($event)"
            @change="saveFieldValue"
          />
        </div>
        <!-- (By default) Text without autocomplete -->
        <div v-else>
          <v-text-field
            v-model="fieldValue"
            :class="{ 'title-text-field': title}"
            filled
            :tabindex="name ? -1 : 0"
            autocomplete="do-not-autofill"
            hide-details
            :autofocus="autofocus"
            :placeholder="fieldName ? fieldName : 'Sans nom'"
            :maxlength="name ? 25 : 255"
            :dense="!title"
            solo
            flat
            overflow-visible
            :prepend-icon="icon"
            @focus="autoSelect($event)"
            @change="saveFieldValue"
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  mdiChevronDown, mdiChevronUp, mdiCloseCircleOutline, mdiOpenInNew, mdiPlus,
} from '@mdi/js'
import {
  computed, defineComponent, ref, watch,
} from '@vue/composition-api'
import moment from 'moment'
import store from '@/store'
import getCompanySuggestions from '@/api/getCompanySuggestions'
import CopyButton from './CopyButton.vue'

export default defineComponent({
  components: {
    CopyButton,
  },
  props: {
    value: {},
    autofocus: {
      type: Boolean,
      default: false,
    },

    /**
     * Field types:
     * - text
     * - checkbox
     */
    fieldId: {
      type: String,
      required: false,
    },
    fieldName: {
      type: String,
    },
    fieldType: {
      type: String,
    },
    personalInfo: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
    },
    title: {
      type: Boolean,
      default: false,
    },
    name: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
  },
  setup(props, context) {
    const { fieldId } = props
    const notesVisible = ref(false)
    const companySearch = ref('')
    const loading = ref(false)
    const menuDatePicker = ref(false)
    const fieldValue = ref(props.value)
    const toggleNotes = () => {
      notesVisible.value = !notesVisible.value
    }

    // On field change, emit the new value
    const saveFieldValue = () => {
      context.emit('input', fieldValue.value)
      context.emit('readyToCommit')
    }

    // On props value change (from parent), update the field value
    watch(
      () => props.value,
      value => {
        fieldValue.value = value
      },
    )
    const onCompanyInput = event => {
      let input = event
      if (!input.name) {
        // Add custom new company
        input = { logo: null, name: event, domain: null }
      }
      fieldValue.value = input
      saveFieldValue()
    }
    const removeCompany = () => {
      context.emit('input', null)
      context.emit('readyToCommit')
    }
    const onDatePickerInput = () => {
      saveFieldValue()
      menuDatePicker.value = false
    }
    const openWebSite = () => {
      if (props.value.domain) {
        window.open(`http://${props.value.domain}`, '_blank')
      }
    }
    const autoSelect = event => {
      setTimeout(() => {
        if (event.target.value) {
          event.target.select()
        }
      }, 0)
    }
    const getCompanyItemText = company => `${company.name || ''}${company.domain || ''}`.trim() || null
    const companySearchSuggestions = ref([])
    const dateFieldHint = computed(() => {
      if (props.value) {
        return moment(props.value).fromNow()
      }

      return null
    })
    const dateFieldText = computed(() => {
      if (props.value) {
        return moment(props.value).format('DD/MM/YYYY')
      }

      return 'Définir une date'
    })
    const existingFieldValues = computed(() => {
      if (props.name) {
        return []
      }

      return store.getters['contacts/getCurrentFieldValuesById'](fieldId)
    })
    const companySuggestions = computed(() => existingFieldValues.value.concat(companySearchSuggestions.value))

    return {
      companySearchSuggestions,
      dateFieldText,
      dateFieldHint,
      menuDatePicker,
      existingFieldValues,
      companySearch,
      companySuggestions,
      loading,
      autoSelect,
      onCompanyInput,
      toggleNotes,
      removeCompany,
      getCompanyItemText,
      fieldValue,
      saveFieldValue,
      icons: {
        mdiCloseCircleOutline,
        mdiPlus,
        mdiOpenInNew,
        mdiChevronDown,
        mdiChevronUp,
      },
      onDatePickerInput,
      moment,
      notesVisible,
      openWebSite,
    }
  },
  watch: {
    async companySearch(newValue) {
      let search = newValue
      if (search !== null && search !== undefined) {
        // Prevent blank search
        search = search.trim()
      }
      if (search) {
        this.loading = true
        const { data } = await getCompanySuggestions(search)
        this.companySearchSuggestions = data
        this.loading = false
      } else {
        // Reset suggestions when search is empty
        this.companySearchSuggestions = []
      }
    },
  },
})
</script>
<style lang="scss">
* >>> .text-field input {
  cursor: pointer;
}

.title-text-field input {
  font-size: 38px;
  font-weight: 800;
  max-height: 100%;
}

.v-menu__content:not(.list-style) .v-list-item {
  height: 3rem;
}
.v-textarea {
  font-size: 16px;
}
.link:hover {
  text-decoration: underline;
  cursor: pointer;
}
</style>
