import {
  mdiAt,
  mdiCalendarRange,
  mdiCardTextOutline,
  mdiCheckboxMarkedCircleOutline,
  mdiDomain,
  mdiPhone,
} from '@mdi/js'

const fieldTypes = [
  {
    icon: mdiCardTextOutline,
    text: 'Champs de texte',
    fieldType: 'text',
    personalInfo: false,
  },
  {
    icon: mdiCheckboxMarkedCircleOutline,
    text: 'Oui/Non',
    fieldType: 'checkbox',
    personalInfo: false,
  },
  {
    icon: mdiAt,
    text: 'Email',
    fieldType: 'email',
    personalInfo: true,
  },
  {
    icon: mdiPhone,
    text: 'Téléphone',
    fieldType: 'phone',
    personalInfo: true,
  },
  {
    icon: mdiDomain,
    text: 'Entreprise',
    fieldType: 'company',
    personalInfo: false,
  },
  {
    icon: mdiCalendarRange,
    text: 'Date',
    fieldType: 'date',
    personalInfo: false,
  },
]

export default fieldTypes

export const getIconByType = fieldType => {
  const fieldTypeIcon = fieldTypes.find(field => field.fieldType === fieldType)

  return fieldTypeIcon ? fieldTypeIcon.icon : null
}
